import { ref, computed, onMounted, h } from 'vue';
import { defineStore } from 'pinia';
import type { Car, CarMeta } from '@/types';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { getAllPostCitiesAndCodes, getVehicleMeta } from '@/libraries/api';
import { useFiltersStore } from '@/stores/filters';
import { storeToRefs } from 'pinia';

export const useDataStore = defineStore('data', () => {
    const filtersStore = useFiltersStore();
    const { filters, filtersSelectedPopup } = storeToRefs(filtersStore);

    const metaTree = ref<any>({});
    const data = ref<{
        carBrands: string[];
        carModels: string[];
        carVersions: string[];
        fuelTypes: string[];
        carTypes: string[];
        carSizes: string[];
        importantFeatures: string[];
        budgetRange: {
            max: number;
            min: number;
            defaultMax: number;
        };
        cars: Car[];
        cities: string[];
        postCodes: string[];
        postCodesAndCities: { [key: string]: string };
        isLoaded: Boolean;
        previousView: string;
    }>({
        carBrands: [],
        carModels: [],
        carVersions: [],
        cities: [],
        postCodes: [],
        postCodesAndCities: {},

        //Intentionally used hardcoded values,
        fuelTypes: ['hybrid', 'electric', 'gasoline', 'diesel'],
        carTypes: [
            'sedan',
            'suv-crossover',
            'coupe',
            'hatch-back',
            'minivan',
            'station-wagon',
            'city-car',
            'convertible',
        ],
        carSizes: ['small', 'compact', 'midsize', 'large'],
        importantFeatures: [
            'towing-capacity',
            'comfort',
            'safety',
            'acceleration',
            'space',
            'environment',
        ],
        budgetRange: {
            max: 150000,
            min: 15000,
            defaultMax: 15000,
        },
        cars: [],
        isLoaded: false,
        previousView: 'suggestedCarsView',
    });

    const carBrands = computed(() => data.value.carBrands);
    const carModels = computed(() => data.value.carModels);
    const carVersions = computed(() => data.value.carVersions);
    const fuelTypes = computed(() => data.value.fuelTypes);
    const carTypes = computed(() => data.value.carTypes);
    const carSizes = computed(() => data.value.carSizes);
    const importantFeatures = computed(() => data.value.importantFeatures);
    const budgetRange = computed(() => data.value.budgetRange);
    const cities = computed(() => data.value.cities);
    const postCodes = computed(() => data.value.postCodes);
    const postCodesAndCities = computed(() => data.value.postCodesAndCities);
    const previousView = computed(() => data.value.previousView);

    const cars = computed(() => {
        return data.value.cars;
    });

    const metaData = ref<CarMeta[]>([]);

    onMounted(() => {
        const queryClient = useQueryClient();
        const vehicleMeta = useQuery({
            queryKey: ['vehicleMeta'],
            queryFn: getVehicleMeta,
            staleTime: Infinity,
            gcTime: Infinity,
        });

        //ToDo: Fix getVehicleMeta(), it should indicate a return type of CarMeta[]
        vehicleMeta.suspense().then((res: any) => {
            let responseData: CarMeta[] = res.data as CarMeta[];
            metaData.value = responseData;

            data.value.carBrands = responseData
                .map((d) => d.make)
                .filter((e, i, a) => a.indexOf(e) === i);
            data.value.carModels = responseData
                .map((d) => d.model)
                .filter((e, i, a) => a.indexOf(e) === i);
            data.value.carVersions = responseData
                .map((d) => d.version)
                .filter((e, i, a) => a.indexOf(e) === i);

            addAllMeta(responseData);
            //filterCarMeta([]);
            data.value.isLoaded = true;
        })

        const ciriesAndCodes = useQuery({
            queryKey: ['citiesAndCodes'], queryFn: getAllPostCitiesAndCodes,
            staleTime: Infinity,
            gcTime: Infinity,
        });

        //ToDo: Fix getAllPostCitiesAndCodes(), it should indicate a return type of CarMeta[]
        ciriesAndCodes.suspense().then((res: any) => {
            let codesAndCities: { [key: string]: string } = res as { [key: string]: string };
            data.value.postCodesAndCities = codesAndCities;
            data.value.postCodes = Object.keys(codesAndCities);
            data.value.cities = Array.from(
                new Set(Object.values(codesAndCities))
            ).sort();
        })
    })


    function addAllMeta(responseData: CarMeta[]) {
        for (const row of responseData) {
            metaTree.value[row.make] ??= {};
            metaTree.value[row.make][row.model] ??= [];
            metaTree.value[row.make][row.model].push(row.version);
        }
    }

    function determineEngineType(carMeta: CarMeta): string {
        const { hybrid_type, fuel_type } = carMeta;
        if (hybrid_type === 'S') {
            return 'hybrid';
        } else if (fuel_type === 'E') {
            return 'electric';
        } else if (fuel_type === 'D') {
            return 'diesel';
        } else if (fuel_type === 'P') {
            return 'gasoline';
        } else if (fuel_type === 'H') {
            return 'hydrogen';
        } else {
            return 'none';
        }
    }

    function activeFuelTypes(make?: string, model?: string, version?: string) {
        if (!make || make === '') return fuelTypes.value;
        let cars = metaData.value.filter((meta) => meta.make === make);

        if (model && model !== '') {
            cars = cars.filter((meta) => meta.model === model);
        }

        if (version && version !== '') {
            cars = cars.filter((meta) => meta.version === version);
        }

        const activeFuelTypes: string[] = [];

        for (const car of cars) {
            for (const fuelType of fuelTypes.value) {
                if (
                    fuelType.includes(determineEngineType(car)) &&
                    !activeFuelTypes.includes(fuelType)
                ) {
                    activeFuelTypes.push(fuelType);
                }
            }
        }

        return activeFuelTypes;
    }

    //Filters possible options of car brands/models/versions
    //based on user's selections of the fuel type / brand / model
    const computeFilteredMetaTree = (filtersData: any) => {
        const result: {
            brands: string[];
            models: string[];
            versions: string[];
        } = {
            brands: [],
            models: [],
            versions: [],
        };

        const structuredMetaTree: any = filterCarMeta(
            filtersData['fuel-type'].value.map((f: any) => f.value)
        );

        for (const brand in structuredMetaTree) {
            for (const model in structuredMetaTree[brand]) {
                structuredMetaTree[brand][model].forEach((version: string) => {
                    result.brands.push(brand);
                    result.models.push(model);
                    result.versions.push(version);
                });
            }
        }

        const unique = (e: string, i: number, a: string[]) =>
            a.indexOf(e) === i;
        const locale = (a: string, b: string) => a.localeCompare(b);
        result.brands = result.brands.filter(unique).sort(locale);
        result.models = result.models.filter(unique).sort(locale);
        result.versions = result.versions.filter(unique).sort(locale);

        const carBrand = filtersData['car-brand'];
        const carModel = filtersData['car-model'];

        if (
            !carBrand.isDefault() &&
            carBrand.value &&
            structuredMetaTree[carBrand.value]
        ) {
            const brandModels = Object.keys(structuredMetaTree[carBrand.value]);
            result.models = result.models.filter((m: string) =>
                brandModels.includes(m)
            );
            const brandVersions = brandModels.flatMap(
                (bm: string) => structuredMetaTree[carBrand.value][bm]
            );
            result.versions = result.versions.filter((v: string) =>
                brandVersions.includes(v)
            );
        }

        if (!carModel.isDefault()) {
            const modelVersions: string[] = [];
            for (const brand in structuredMetaTree) {
                for (const model in structuredMetaTree[brand]) {
                    if (model !== carModel.value) continue;
                    modelVersions.push(...structuredMetaTree[brand][model]);
                }
            }

            result.versions = result.versions.filter((v: string) =>
                modelVersions.includes(v)
            );
        }

        return {
            brands: result.brands.filter(unique),
            models: result.models.filter(unique),
            versions: result.versions.filter(unique),
        };
    };

    const filteredMetaTree = computed(() =>
        computeFilteredMetaTree(filters.value)
    );
    const filteredMetaTreeFiltersPopUp = computed(() =>
        computeFilteredMetaTree(filtersSelectedPopup.value)
    );

    function filterCarMeta(fuelType: Array<string>) {
        if (fuelType.length === 0) return metaTree.value;

        const output: any = {};

        for (const row of metaData.value) {
            const { make, model, version, fuel_type, hybrid_type } = row;

            if (!fuelType.includes(determineEngineType(row))) continue;

            if (!output[make]) {
                output[make] = {};
            }
            if (!output[make][model]) {
                output[make][model] = []; // Initialize as an array
            }

            output[make][model].push(version);
        }

        return output;
    }

    function changePreviouslyLoadedView(previousView: string) {
        data.value.previousView = previousView;
    }

    return {
        carBrands,
        carModels,
        carVersions,
        fuelTypes,
        carTypes,
        carSizes,
        importantFeatures,
        budgetRange,
        cities,
        postCodes,
        postCodesAndCities,
        previousView,
        changePreviouslyLoadedView,
        cars,
        metaData,
        metaTree,
        filteredMetaTree,
        filteredMetaTreeFiltersPopUp,
        addAllMeta,
        determineEngineType,
        activeFuelTypes,
    };
});
