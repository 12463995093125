<script setup lang="ts">
import { getRelatedBlogPosts } from '@/libraries/api';
import { useQuery } from '@tanstack/vue-query';
import BlogItem from '../components/BlogItem.vue';

const { data: blogs } = useQuery({
    queryKey: ['relatedBlogPosts'],
    queryFn: () => getRelatedBlogPosts(6),
    staleTime: 3e5,
});
</script>

<template>
    <div class="row">
        <BlogItem v-for="blog in blogs" :key="blog.id" :id="blog.id" :title="blog.title" :text="blog.abstract"
            :source="blog.url_to_source" :imgSrc="blog.imageSource" :imgAlt="blog.title" :imtTitle="blog.title"></BlogItem>
    </div>
</template>
