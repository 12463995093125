import type { ApiFilter, Filter } from '@/types';
import { computed } from 'vue';

import { getI18nInstance } from '.././libraries/i18n';
import { ATTRIBUTE_SCHEMA_MAPPING } from '@/libraries/api/adapterMapping';
import { useDataStore } from '@/stores/data';
import type { Composer } from 'vue-i18n';

export const carTypeFilter: Filter<
    Array<{
        title: string;
        value: string;
    }>
> = {
    type: 'car-type',

    value: [],
    defaultValue: [],
    valueFormat: 'multiple',
    options: computed(() => []),
    noPreference: false,

    isDefault: function (): Boolean {
        return this.value == this.defaultValue;
    },
    toFormatted: function (child: string): string {
        const i18n = getI18nInstance();
        if (i18n) {
            const t = i18n.global.t as Composer<{}, {}, {}, true>['t'];
            return t('car_type.' + child.replace('-', '_'));
        }

        return '';
    },
    toUrlQueryFormatted: function (): string {
        const result: string[] = [];
        this.value.forEach((v) => {
            result.push(v.value);
        });
        return result.join(',');
    },
    toFilterFromUrlQuery: function (value): any {
        return {
            title: this.toFormatted(value),
            value: value,
        };
    },
    hasValidValue: function (value): any {
        return useDataStore().carTypes.includes(value);
    },
    onValueChanged: function (): void { },
    toApiFilter: function (): ApiFilter[] {
        const VALUE_SCHEMA_MAPPING = new Map<string, string>([
            ['sedan', 'SA,SH'],
            ['suv-crossover', 'CR,OD,CP,PU'],
            ['coupe', 'CO'],
            ['hatch-back', 'HA'],
            ['minivan', 'FW,MM,BU'],
            ['station-wagon', 'CC,ES'],
            ['city-car', 'MC,4C'],
            ['convertible', 'CA,TA'],
        ]);

        const apiFilters: ApiFilter[] = [];

        for (const carType of this.value) {
            apiFilters.push({
                attribute: ATTRIBUTE_SCHEMA_MAPPING.get(this.type) ?? this.type,
                operator: 'in',
                value: VALUE_SCHEMA_MAPPING.get(carType.value) ?? carType.value,
            });
        }

        return apiFilters;
    },
    toApiSort: function (): string[] {
        return [];
    },
};
