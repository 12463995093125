import type { ApiFilter, Filter } from '@/types';
import { computed } from 'vue';
import type { Composer } from 'vue-i18n';
import { getI18nInstance } from '.././libraries/i18n';
import { ATTRIBUTE_SCHEMA_MAPPING } from '../libraries/api/adapterMapping';
import { useDataStore } from '@/stores/data';

export const importantFeatureFilter: Filter<
    Array<{
        title: string;
        value: string;
    }>
> = {
    type: 'important-feature',
    valueFormat: 'multiple',
    value: [],
    defaultValue: [],
    noPreference: false,

    options: computed(() => []),

    isDefault: function (): Boolean {
        return this.value == this.defaultValue;
    },

    toFormatted: function (child: string): string {
        const i18n = getI18nInstance();
        if(i18n){
            const t = i18n.global.t as Composer<{}, {}, {}, true>['t'];
            return t('important_feature.' + child.replace('-', '_'));
        }
  
        return '';
    },
    toUrlQueryFormatted: function (): string {
        const result: string[] = [];
        this.value.forEach((v) => {
            result.push(v.value);
        });
        return result.join(',');
    },
    toFilterFromUrlQuery: function (value): any {
        return {
            title: this.toFormatted(value),
            value: value,
        };
    },
    hasValidValue: function (value): any {
        return useDataStore().importantFeatures.includes(value);
    },
    onValueChanged: function (): void { },
    toApiFilter: function (): ApiFilter[] {
        return [];
    },
    toApiSort: function (): string[] {
        return [];
    },
};
