import * as Sentry from '@sentry/vue';

export default function initSentry(app: any, router: any, dsn: string) {
    Sentry.init({
        app,
        dsn,
        //ToDo: if sentry breaks, check this line - ilyas
        tracePropagationTargets: [
            'api.autoselectr.k.elnino-acceptance.com',
            'api.autoselectr.k.elnino-production.com',
            'api.autoselectr.com',
            /^\//,
        ],
        integrations: [
            Sentry.browserTracingIntegration({
                router: router,
            }),
        ],
        tracesSampleRate: 1.0,
    });
}
